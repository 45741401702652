.section {
    padding: 20px;
    scroll-snap-align: start;
    position: relative;
}

.section > h3 {
    font-weight: 600;
    font-size: 20px;
    margin-left: 20px;
}


.iconsContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 20px;
    gap: 20px;

    align-items: start;
    align-content: center;
}

/* Use to track if the area is in sight */
.trackerBox { 
          z-index: -1;

          position: absolute;
          /* background: red; */
          height: 100px;
          width: 100px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -60%);
}
