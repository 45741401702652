.container {
    border: 1px solid var(--border-primary, #c4bfb6);
    padding: 16px;
    margin: 16px;
    margin-top: 24px;
    margin-left: 0;
    max-width: 650px;

    &:has(:focus-within) {
        border-color:  var(--border-brand,  #f26924);
    }
}

.triggeredContainer {
    background: var(--background-secondary, #F8F6F4);
    
}

.container > p {
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
}

.inputContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.inputContainer > textarea {
    resize: none;
    border: none;
    outline: none;
    width: 100%;
    height: 100px;
    padding: 5px;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    color:  var(--text-primary, #000);

    &:placeholder-shown {
        color: var(--text-secondary, #686869);
      }
}

.ctaButton {
    height: 35px; 
    width: 135px;
    padding-left: 20px;
    padding-right: 20px;
}

.ctaButtonTriggered { 
    border-color: var(--border-primary, #C6BFB4);
    cursor: pointer;
}
.ctaButtonTriggered > span {
    font-weight: 600;
    font-size: 14px;
}

.ctaButtonContents {
    display: flex;
    align-items: center;
    gap: 4px;
}

.actionButtons {
    display: flex;
    gap: 5px;
    padding: 10px;
    padding-left: 0;
}

.actionButtons > button {
    color: var(--text-secondary, #686869);
    background-color: var(--background-secondary,#F8F6F4);
    border: 1px solid var(--border-secondary,#C6BFB4);
    padding: 8px;
    font-size: 14px;
    font-weight: 400;
}
