.windowContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    background-color: var(--background-primary, #fff);

    padding: 15px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.12);
}

.windowContainer > img {
    height: 200px;
    grid-area: image;
}


.windowHeroContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "content_area image";
    justify-items: center;


    padding: 32px 40px;
    border: 1px solid var(--border-primary, #C6BFB4);
}
.windowHeroContainer > img {
    height: initial;
}
@media (width < 1345px) {
  .windowHeroContainer {
    display: flex;
    flex-direction: column;
  }
}

.series {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
}

.windowContent{
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 8px;
    width: 100%;

    flex-grow: 1;
    grid-area: content_area;
}


.windowContent > h3 {
    font-weight: bold;
}
.windowContent > p {
    color: var(--text-secondary, #686869);
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    max-width: 723px;
}

.heroContent {
    padding: initial;
}
.actionButtons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    align-self: flex-end;
}

.tags {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
}

.tag {
    background-color: var(--background-secondary, #F8F6F4);
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.otherCTA {
    border-color: var(--border-secondary, #C6BFB4);
    width: 100%;
}

.readMoreButton {
    background-color: var(--background-primary, #F8F6F4);
    border: 1px solid var(--border-secondary, #E2E1DF);

    display: flex;
    flex: row;
    gap: 8px;
    align-items: center;
    justify-content: center;
    padding: 5px 15px;

    cursor: pointer;
}