 .link {

      display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 5px;

        color: black;
        text-decoration: none;
        font-weight: 600;
        font-size: 14px;
    
        border: 3px solid rgb(242, 105, 36);
        border-radius: 30px;
        padding: 5px 15px;
        text-wrap: nowrap;
}
