
/* tiles */
.tileContainer {
    padding: 24px;
    background-color: var(--background-primary, #FFF);
    border: 1px solid #E2E1DF;
    height: 100%;
    cursor: pointer;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 12px;
}

.tileContainer > img {
    height: 56px;
    aspect-ratio: 2/1;
    padding: 1px;
    
/* user-drag: none; */
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.tileContainer > p {
    color:  var(--text-secondary,#686869);
    font-weight: 600;
    margin-left: 5px;
}

.tileActivated {
    border-color: var(--border-brand, #f26924);
    background-color: var(--background-secondary, #F8F6F4);
}

.tileActivated > img {
    border: initial;
}