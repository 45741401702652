.modalContainer {
  width: 520px;
  height: 330px;
  padding: 60px;
  border-radius: 20px;
  position: fixed;
  z-index: 1;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -60%);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: white;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.modalContainer > img {
  width: 250px;
}

.modalContainer > .modalText {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.cta {

  padding: 12px 24px;
  gap: 6px;
}