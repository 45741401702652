.imagesContainer{
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 150px;
  gap: 20px;
  overflow: hidden;
  max-height: 100vh;
  z-index: -1;
  position: absolute;
  top: -5px;
  left: -175px;

}

.imagesContainer > img {
  border-radius: 10px;
  object-fit: cover;
  width: 100%;
  height: 100%;

  /* not dragable images */

  /* user-drag: none; */
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}