/* Nav 2 ---  */
.nav2 { 
  background-color: var(--background-primary, #fff);
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.logo {
  width: 197px;
  height: 36px;
}
.nav2 > ul {
  list-style-type: none;
  margin: 0;


  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}


 .link > a , .link{
  color: var(--text-primary, #0000);
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 14px;
}
.link > a:hover {
  border-bottom: 5px solid var(--border-brand, #f26924);
}

.mag > a.hover {
  border-bottom: initial;
}