.container {
    display: flex;
    background-color: var(--background-primary, #FFF);
    gap: 23px;
    margin: 24px;
    max-width: 712px;
    align-self: center;
}

.container > img {
    width: 323px;
    flex-shrink: 0;
    align-self: stretch;
}

.textArea {
    padding: 30px 40px;
    padding-left: 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.textArea div {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.textArea  h2 {
    font-size: 30px;
    line-height: 36px;
    font-weight: 600;
}

.cta{
     
}