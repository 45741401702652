.container {
    display: grid;
    grid-template-columns: 600px 1fr;
    grid-template-rows: auto;
    grid-template-areas: "left right";
}

.rightSide {
    height: calc(100vh - 128px); 

    background-color: var(--background-secondary,#F8F6F4);
    overflow-y: scroll;
    scroll-behavior: smooth;

    padding: 10px;
    grid-area: right;

    display: flex;
    flex-direction: column;
    align-items: center;
}
.innerRightSide > h3 {

    color: var(--text-secondary, #686869);
    font-size: var(--font-size-500, 20px);
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    /* padding-left: 20px; */

    align-self: flex-start;
}

.leftSide {
    height: calc(100vh - 128px);
    grid-area: left;
    display: flex;
    flex-direction: column;
    background-color: var(--background-primary, #FFF);
}

/* Section */
.leftSide > .heading {
    padding: 48px;
}
.heading > h2 {
    font-size: 36px;
    line-height: 43.2px;
}

.leftSide > .sections {
    overflow-y: scroll;
    scroll-behavior: smooth;
}


/* Window suggestion */
@media (width > 1345px) {
    .suggestions {
        ---repeat: 2;
    }
} 

.suggestions {
    display: grid;
    grid-template-columns: repeat(var(---repeat, auto-fit), 1fr);
    gap: 20px;
    /* padding: 20px; */
}