/* ---- Nav 1 */
.nav1 {
  background-color: black;
}

.nav1 > ul {
  list-style-type: none;
  margin: 0;


  height: 100%;

  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-items: center;
  justify-content: flex-end;
  padding: 16px;
}

 .nav1link > a , .nav1link{
  color: white;
  text-decoration: none;
}
.nav1link > a:hover {
  text-decoration: underline;
}
