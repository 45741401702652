@import url("https://use.typekit.net/gnj2kqo.css");

/* --------Colors -------- */

:root {
/* Collection: Base Colors */
  --brand: #f26924;
  --brand-dark: #cb4c0c;
  --black: #000000;
  --gray-100: #f8f6f4;
  --gray-200: #eeedec;
  --gray-300: #E2E1DF;
  --gray-400: #c4bfb6;
  --gray-500: #686869;
  --white: #ffffff;


/* Collection: Colors */

  --background-primary: var(--white);
  --background-secondary: var(--gray-100);
  --background-tertiary: var(--gray-200);
  --background-inverse: var(--black);
  --background-brand: var(--brand);
  --background-brand-secondary: var(--brand-dark);
  --text-primary: var(--black);
  --text-secondary: var(--gray-500);
  --text-brand: var(--brand-dark);
  --text-white: rgba(255,255,255,1);
  --border-primary: var(--gray-400);
  --border-secondary: var(--gray-300);
  --border-brand: var(--brand);


/* Collection: Typography */

  /* --font family-heading: "Futura PT";
 --font family-body: "Open Sans"; */
  --font-size-350: 14px;
  --font-size-400: 16px;
  --font-size-450: 18px;
  --font-size-500: 20px;
  --font-size-600: 24px;
  --font-size-800: 32px;
  --font-size-900: 36px;
  --font-size-1000: 40px;
}


* {
  /* Debug line for box spacing */
  /* outline: 1px solid #f00 !important; */
}



/*
  1. Use a more-intuitive box-sizing model.
*/
*, *::before, *::after {
  box-sizing: border-box;
}
/*
  2. Remove default margin
*/
* {
  margin: 0;
}
/*
  Typographic tweaks!
  3. Add accessible line-height
  4. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
/*
  5. Improve media defaults
*/
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}
/*
  6. Remove built-in form typography styles
*/
input, button, textarea, select {
  font: inherit;
}
/*
  7. Avoid text overflows
*/
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
/*
  8. Create a root stacking context
*/
#root, #__next {
  isolation: isolate;
}

/* --------- */
body {
  margin: 0;
  font-family: "futura-pt", sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

p{
   font-size: var(--font-size-400, 16px);
}

li{
    margin-left: var(--font-size-400, 16px);
}

