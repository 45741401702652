.container {
    padding: 24px;
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 1px solid var(--border-secondary, #c6bfb4);
    background-color: var(--light-orange, #EEEDEC);

    width: 100%;
}
.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.imgContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.imgContainer > p {
    font-size: 14px;
    color: var(--text-secondary,#686869);
    text-transform: uppercase;
}

.heroWindowRecommendation{
    background-color: var(--brand-dark,#cb4c0c);
    color: var(--background-primary, #FFF);
    padding: 4px 8px;
    font-size: 14px;
    font-weight: 400;

    display: flex;
    gap: 8px;
    flex-direction: row;
    align-items: center;
}
.reasoningContainer {
    display: flex;
    flex-direction: column;
}

.reasoningContainer > button {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    align-self: flex-end;
}

.reasoning  a {
    border-bottom: 2px solid var(--border-brand,#f26924);
}